import React, { useState, useRef } from 'react'

import FadeCollapse from 'src/components/formFields/fadeCollapse'

export default ({
    planYourVisitFields,
}) => {
    const [openDropdowns, setOpenDropdowns] = useState([])
    const openDropdownsRef = useRef(openDropdowns)

    openDropdownsRef.current = openDropdowns

    const toggleDropDown = index => {
        const _openDropDowns = [...openDropdownsRef.current]

        const indexIfExists = _openDropDowns.indexOf(index)

        if (indexIfExists === -1) {
            _openDropDowns.push(index)
        } else {
            _openDropDowns.splice(indexIfExists, 1)
        }

        setOpenDropdowns(_openDropDowns)
    }

    const hours = planYourVisitFields.planningYourVisit.hours

    const getToday = openingHours => {
        const date = new Date()
        const weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
        const dayOfWeek = weekday[date.getDay()]

        const today = openingHours.find(day => day.day === dayOfWeek)

        return today
    }

    return (
        <div className="container">
            <div>
                <div dangerouslySetInnerHTML={{ __html: planYourVisitFields.planningYourVisit.title }} />
                <div dangerouslySetInnerHTML={{ __html: planYourVisitFields.planningYourVisit.copy }} />
                {hours ?
                    <div className="planYourVisit-openingHours">
                        <h3 dangerouslySetInnerHTML={{ __html: 'Opening Hours' }} />
                        {hours.map((row, index) => {
                            const {
                                title,
                                hoursType,
                                openingHours,
                                openingHoursCustom,
                                openingHoursDays,
                                openingHoursTimes
                            } = row

                            const isOpen = openDropdowns.indexOf(index) !== -1

                            return (
                                <div className="planYourVisit-openingHours-group" key={index}>
                                    <div className="planYourVisit-openingHours-group-title" dangerouslySetInnerHTML={{ __html: title }} />
                                    <div className={'hours-group'}>
                                        {hoursType === 'single' ?
                                            <div className={'hours-row'}>
                                                <div dangerouslySetInnerHTML={{ __html: openingHoursDays }} />
                                                <div dangerouslySetInnerHTML={{ __html: openingHoursTimes }} />
                                            </div>
                                            : hoursType === 'custom' ?
                                                <div className={'hours-row-custom'}>
                                                    {openingHoursCustom.map((row, index) => {
                                                        const {
                                                            openingHoursDays,
                                                            openingHoursTimes,
                                                        } = row

                                                        return (
                                                            <div key={index}>
                                                                <div dangerouslySetInnerHTML={{ __html: openingHoursDays }} />
                                                                <div dangerouslySetInnerHTML={{ __html: openingHoursTimes }} />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                :
                                                <div className="planYourVisit-openingHours-group-hours-multiple">
                                                    <div
                                                        className={'top-row hours-row'}
                                                        onClick={() => toggleDropDown(index)}
                                                    >
                                                        <div>
                                                            Today
                                                        </div>
                                                        <div>
                                                            {(() => {
                                                                const today = getToday(openingHours)

                                                                const todayTimes = today.hours.split(' - ')

                                                                const openHour = parseInt(todayTimes[0].replace(':00am', '').replace(':00 am', ''))

                                                                const date = new Date()
                                                                const currentHour = date.getHours()

                                                                if (currentHour <= openHour) {
                                                                    return today.hours
                                                                } else {
                                                                    return `Open until ${todayTimes[1]}`
                                                                }
                                                            })()}
                                                            <i className={`fe fe-chevron-${isOpen ? 'up' : 'down'}`} />
                                                        </div>
                                                    </div>
                                                    <FadeCollapse open={isOpen}>
                                                        <div className={'dropdown'}>
                                                            {openingHours.map((row, index) => {
                                                                const {
                                                                    day,
                                                                    hours
                                                                } = row

                                                                const today = getToday(openingHours)

                                                                return (
                                                                    <div key={index} className={`hours-row ${today.day === day ? 'today' : ''}`}>
                                                                        <div dangerouslySetInnerHTML={{ __html: day === 'publicholidays' ? 'Public Holidays' : day }} />
                                                                        <div dangerouslySetInnerHTML={{ __html: hours }} />
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </FadeCollapse>
                                                </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : null}
            </div>
        </div>
    )
}