import React, { useState, useRef } from 'react'
import FadeCollapse from 'components/formFields/fadeCollapse'

export default ({
    options
}) => {
    const [openSections, setOpenSections] = useState([])
    const openSectionsRef = useRef(openSections)

    openSectionsRef.current = openSections

    const handleToggle = index => {
        const _openSections = [...openSectionsRef.current]

        const indexIfExists = _openSections.indexOf(index)

        if (indexIfExists !== -1) {
            _openSections.splice(indexIfExists, 1)
        } else {
            _openSections.push(index)
        }

        setOpenSections(_openSections)
    }

    return (
        <div className="accordion">
            {options.map((row, index) => {
                const isOpen = openSections.indexOf(index) !== -1

                const {
                    icon,
                    title,
                    copy,
                    slug
                } = row

                return (
                    <div
                        key={index}
                        onClick={() => handleToggle(index)}
                        onKeyDown={() => handleToggle(index)}
                        role="button"
                        tabIndex={index}
                        className={isOpen === true ? 'open' : ''}
                    >
                        <div className="toggle">
                            <span />
                        </div>
                        <div className="heading">
                            {icon &&
                                <div className={'icon ' + slug}>
                                    <img src={icon} alt={slug} />
                                </div>
                            }
                            <h3 dangerouslySetInnerHTML={{ __html: title }} />
                        </div>
                        <FadeCollapse open={isOpen}>
                            <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                        </FadeCollapse>
                    </div>
                )
            })}
        </div>
    )
}