import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import SEO from 'components/seo'
import { useIsMobile } from 'utils'

import PlanningYourVisit from 'components/planYourVisit/planningYourVisit'
import GettingHere from 'components/planYourVisit/gettingHere'
import Parking from 'components/planYourVisit/parking'
import Accessibility from 'components/planYourVisit/accessibility'
import FAQs from 'components/planYourVisit/faqs'

import SidebarMenu from 'components/sidebarMenu'

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                planYourVisitFields {
                    planningYourVisit {
                        title
                        copy
                        hours {
                            title
                            hoursType
                            openingHours {
                                day
                                hours
                            }
                            openingHoursCustom {
                                openingHoursDays
                                openingHoursTimes
                            }
                            openingHoursDays
                            openingHoursTimes
                        }
                    }
                    gettingHere {
                        title
                        copy
                        address
                        phone
                        transport {
                            title
                            copy
                            types {
                                type
                                copy
                            }
                        }
                        title2
                        copy2
                        map {
                            mediaItemUrl
                        }
                    }
                    parking {
                        title
                        copy
                        parkingRates {
                            title
                            rates {
                                title
                                rate
                            }
                        }
                    }
                    accessibility {
                        title
                        copy
                    }
                    faqs {
                        title
                        copy
                        faqs {
                            question
                            answer
                        }
                    }
                }
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
        }
    }
`

export default ({
    data,
    location
}) => {
    const {
        planYourVisitFields,
    } = data.siteFields.page

    const isMobile = useIsMobile()

    const handleScroll = useCallback((id) => {
        const element = document.getElementById(id)

        const offsetAmount = id === 'planYourVisit-planningYourVisit' ? isMobile ? 170 : 70 : 70
        const y = element.getBoundingClientRect().top + window.pageYOffset - offsetAmount

        window.scrollTo({ top: y, behavior: 'smooth' })

        return false

    }, [isMobile])

    const [menuValue, setMenuValue] = useState('planYourVisit-planningYourVisit')

    useEffect(_ => {
        const handleScrolling = _ => {
            let _menuValue = ''

            chapters.some(chapter => {
                const component = document.getElementById(chapter.key)

                if (component) {
                    if (component.getBoundingClientRect().top + component.offsetHeight - 100 > 0) {
                        _menuValue = chapter.key
                        return true
                    }
                }
            })

            setMenuValue(_menuValue)
        }

        window.addEventListener('scroll', handleScrolling)

        return _ => {
            window.removeEventListener('scroll', handleScrolling)
        }
    }, [])

    const chapters = [
        {
            key: 'planYourVisit-planningYourVisit',
            label: "Planning your Visit",
            Component: PlanningYourVisit
        },
        {
            key: 'planYourVisit-gettingHere',
            label: "Getting Here",
            Component: GettingHere
        },
        {
            key: 'planYourVisit-parking',
            label: "Parking",
            Component: Parking
        },
        {
            key: 'planYourVisit-accessibility',
            label: "Services and Amenity",
            Component: Accessibility
        },
        {
            key: 'planYourVisit-faqs',
            label: "FAQs",
            Component: FAQs,
            hidden: !planYourVisitFields.faqs.faqs
        },
    ]

    return (
        <Layout location={location}>
            <SEO title='Plan Your Visit' />
            <div className="planYourVisit">
                <SidebarMenu
                    menuValue={menuValue}
                    chapters={chapters}
                    handleScroll={handleScroll}
                />
                {chapters.map(chapter => {
                    const {
                        key,
                        Component,
                        hidden
                    } = chapter

                    if (hidden) {
                        return null
                    }

                    return (
                        <div key={key} className={key} id={key}>
                            <Component
                                planYourVisitFields={planYourVisitFields}
                            />
                        </div>
                    )
                })}
            </div>
        </Layout>

    )
}
