import React from 'react'

export default ({
    planYourVisitFields: {
        parking
    }
}) => {
    const parkingRates = parking.parkingRates

    return (
        <div className="container">
            <div>
                <h2 dangerouslySetInnerHTML={{ __html: parking.title }} />
                <div dangerouslySetInnerHTML={{ __html: parking.copy }} />
                <div className="planYourVisit-parking-rates">
                    {parkingRates.map((row, index) => {
                        const {
                            title,
                            rates
                        } = row

                        return (
                            <div key={index}>
                                <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                {rates.map((row, index) => {
                                    const {
                                        title,
                                        rate
                                    } = row

                                    return (
                                        <div key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: title }} />
                                            <div dangerouslySetInnerHTML={{ __html: rate }} />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}