import React, { useContext, useEffect, useRef } from 'react'
import { MainContext } from 'context/context'
import stickybits from 'stickybits'

import { useIsMobile } from 'utils'

export default ({
    menuValue,
    handleScroll,
    chapters,
    noMenuCover
}) => {
    const {
        hasScrolled,
        searchOpen
    } = useContext(MainContext)

    const isMobile = useIsMobile()
    const isMobileRef = useRef(isMobile)
    isMobileRef.current = isMobile

    const elementRefs = useRef({})
    const parentRef = useRef(null)

    const _window = typeof window !== 'undefined' ? window : null

    useEffect(() => {
        if (_window) {
            setTimeout(() => {
                if (!isMobileRef.current) {
                    stickybits('.sidebar-menu', {
                        stickyBitStickyOffset: 30
                    })
                }
            }, 10)
        }
    }, [_window])

    useEffect(() => {
        const element = elementRefs.current[menuValue]

        if (element) {
            const bounds = element.getBoundingClientRect()

            const currentScrollPosition = parentRef.current.scrollLeft

            if (bounds.x + bounds.width > 500) {

                const newLeft = currentScrollPosition + (bounds.x - 500) + bounds.width + 50

                parentRef.current.scrollTo({
                    left: newLeft,
                    behavior: 'smooth'
                })
            } else if (bounds.x < 0) {
                const newLeft = currentScrollPosition + (bounds.x) - 30

                parentRef.current.scrollTo({
                    left: newLeft,
                    behavior: 'smooth'
                })
            }
        }
    }, [menuValue])

    return (
        <>
            {!noMenuCover ?
                <div className={`sidebar-menu-header-cover ${hasScrolled ? 'scrolled' : ''}`} />
                : null}
            <div className={`sidebar-menu ${(isMobile && hasScrolled) ? 'scrolled' : ''} ${(isMobile && searchOpen) ? 'hidden' : ''}`}>
                <div className="container">
                    <ul ref={ref => parentRef.current = ref}>
                        {chapters.map(chapter => {
                            const {
                                key,
                                label,
                                hidden
                            } = chapter

                            if (hidden) {
                                return null
                            }

                            return (
                                <li
                                    key={key}
                                    className={menuValue === key ? 'active' : ''}
                                    ref={ref => elementRefs.current[key] = ref}
                                >
                                    <button
                                        onClick={() => handleScroll(key)}
                                        onKeyDown={() => handleScroll(key)}
                                        dangerouslySetInnerHTML={{ __html: label }}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>
    )
}