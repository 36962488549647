import React, { useState } from 'react'

import { cloneDeep } from 'lodash'

import IconCarAndMotorbike from "images/carandmotorbike.svg"
import IconTrain from "images/train.svg"
import IconTram from "images/tram.svg"
import IconPedestrian from "images/pedestrian.svg"
import IconBicycle from "images/bicycle.svg"
import IconBus from "images/bus.svg"

import Accordion from 'components/accordion'

import GoogleMap from 'components/googleMap'

export default ({
    planYourVisitFields: {
        gettingHere
    }
}) => {

    const [openTransportGroups, setOpenTransportGroup] = useState([])

    const setTransportOpen = openKey => {
        const _openTransportGroups = cloneDeep(openTransportGroups)

        const indexIfExists = _openTransportGroups.indexOf(openKey)

        if (indexIfExists === -1) {
            _openTransportGroups.push(openKey)
        } else {
            _openTransportGroups.splice(indexIfExists, 1)
        }

        setOpenTransportGroup(_openTransportGroups)
    }

    const transportOptions = gettingHere.transport.types

    return (
        <div className="container">
            <div>
                <h2 className="red" dangerouslySetInnerHTML={{ __html: gettingHere.title }} />
                <div dangerouslySetInnerHTML={{ __html: gettingHere.copy }} />
                <div className="planYourVisit-gettingHere-details">
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: gettingHere.address }} />
                        <a href={'tel:' + gettingHere.phone} dangerouslySetInnerHTML={{ __html: gettingHere.phone }} />
                    </div>
                    <GoogleMap
                        className={'planYourVisit-gettingHere-map'}
                    />
                </div>
                <div className="planYourVisit-gettingHere-transport">
                    <h2 dangerouslySetInnerHTML={{ __html: gettingHere.transport.title }} className="red" />
                    <div dangerouslySetInnerHTML={{ __html: gettingHere.transport.copy }} />
                    <Accordion
                        options={transportOptions.map(option => {
                            const {
                                type,
                                copy
                            } = option

                            let icon = ''

                            switch (type) {
                                case 'carandmotorbike':
                                    icon = IconCarAndMotorbike
                                    break
                                case 'train':
                                    icon = IconTrain
                                    break
                                case 'tram':
                                    icon = IconTram
                                    break
                                case 'pedestrian':
                                    icon = IconPedestrian
                                    break
                                case 'bicycle':
                                    icon = IconBicycle
                                    break
                                case 'bus':
                                    icon = IconBus
                                    break
                            }

                            return {
                                title: type === 'carandmotorbike' ? 'Car and Motorbike' : type,
                                copy,
                                icon,
                                slug: type
                            }
                        })}
                    />
                </div>
                <div className="planYourVisit-gettingHere-entering">
                    <h2 dangerouslySetInnerHTML={{ __html: gettingHere.title2 }} />
                    <div>
                        {gettingHere.copy2 && <div dangerouslySetInnerHTML={{ __html: gettingHere.copy2 }} />}
                        {gettingHere.map && <img src={gettingHere.map.mediaItemUrl} alt={gettingHere.title2} className={gettingHere.copy2 ? '' : 'nocopy'} />}
                    </div>
                </div>
            </div>
        </div>
    )
}