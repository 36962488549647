import React, { useState } from 'react'

import { cloneDeep } from 'lodash'

export default ({
    planYourVisitFields: {
        faqs
    }
}) => {
    const [openFAQGroups, setOpenFAQGroup] = useState([])

    const setFAQOpen = openKey => {
        const _openFAQGroups = cloneDeep(openFAQGroups)

        const indexIfExists = _openFAQGroups.indexOf(openKey)

        if (indexIfExists === -1) {
            _openFAQGroups.push(openKey)
        } else {
            _openFAQGroups.splice(indexIfExists, 1)
        }

        setOpenFAQGroup(_openFAQGroups)
    }

    const _faqs = faqs.faqs

    return (
        <div className="container">
            <div>
                <h2 dangerouslySetInnerHTML={{ __html: faqs.title }} />
                <div dangerouslySetInnerHTML={{ __html: faqs.copy }} />
                <div className="planYourVisit-faqs-faqs">
                    {_faqs.map((row, index) => {
                        const openKey = index
                        const isOpen = openFAQGroups.indexOf(openKey) !== -1

                        const {
                            question,
                            answer
                        } = row

                        return (
                            <div key={index} onClick={() => setFAQOpen(openKey)} onKeyDown={() => setFAQOpen(openKey)} role="button" tabIndex={index} className={isOpen === true ? 'open' : ''}>
                                <div className="planYourVisit-faqs-faqs-toggle" dangerouslySetInnerHTML={{ __html: '<span></span>' }} />
                                <div className="planYourVisit-faqs-faqs-question">
                                    <h3 dangerouslySetInnerHTML={{ __html: question }} />
                                </div>
                                <div className="planYourVisit-faqs-faqs-answer" dangerouslySetInnerHTML={{ __html: answer }} />
                            </div>
                        )
                    })}
                </div>
                <div>
                </div>
            </div>
        </div>
    )
}