import React from 'react'

export default ({
    planYourVisitFields: {
        accessibility
    }
}) => {
    return (
        <div className="container">
            <div>
                <h2 dangerouslySetInnerHTML={{ __html: accessibility.title }} />
                <div dangerouslySetInnerHTML={{ __html: accessibility.copy }} />
            </div>
        </div>
    )
}